<template>
  <div class="container">
    <table-page dataName="topicList"
                ref="table"
                :params="{activity_id:$route.query.id}"
                :request="questionnaireTopics">
      <template slot="button">
        <el-button type="primary"
                   v-permission="['ADMIN']"
                   @click="$refs.SetTopic.showDialog(null,$route.query.id)">添加题目</el-button>
      </template>
      <el-table-column prop="id"
                       show-overflow-tooltip
                       label="Id">
      </el-table-column>
      <el-table-column prop="problem"
                       show-overflow-tooltip
                       label="题目">
      </el-table-column>
      <!-- <el-table-column prop="gift_id"
                       show-overflow-tooltip
                       label="排序">
      </el-table-column> -->
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="text"
                     class="mr-15"
                     @click="$refs.SetTopic.showDialog(scope,$route.query.id)">编辑</el-button>
          <el-button type="text"
                     class="mr-15"
                     @click="delTopic(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </table-page>
    <SetTopic ref="SetTopic"
              :aid="$route.query.id"
              @on-success="$refs.table.fetch()" />
  </div>
</template>

<script>
import SetTopic from './components/SetTopic'
import TablePage from '@/components/TablePage'
import { questionnaireTopics, questionnaireDelTopic } from '@/api/market'
export default {
  name: 'MemberAppointmentGoods',
  data () {
    return {
      questionnaireTopics
    }
  },
  components: {
    TablePage,
    SetTopic
  },
  methods: {
    delTopic (id) {
      this.$confirm('确认删除', {
        type: 'warning'
      }).then(async () => {
        const res = await questionnaireDelTopic({
          id: id
        })
        if (res.meta.code === 0) {
          this.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success'
          })
          this.$refs.table.fetch()
        }
      })
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
</style>
